

html {
  scroll-behavior: smooth;
  font-family: Lato;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: #5FF900;
}

::selection {
  color: black;
  background: #5FF900;
}


body{
  background-color: #0A0A0A;
  font-family: Inter;
}

.App {
  text-align: center;
}


section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;


}

.normal{
height: 100%;
}

.bottom100Padding{
  padding-bottom: 150px;
}